export enum Icons {
	STATISTICS = "statistics",
	SETTINGS = "settings",
	TIMER = "timer",
	GOLD = "gold",
	SILVER = "silver",
	BRONZE = "bronze",
	KNOWLEDGE = "knowledge",
	DIFFICULTY = "difficulty",
	LEARNING_CURVE = "learningCurve",
	SCORES = "scores",
	CUP = "cup",
	CHECK_CORRECT = "checkCorrect",
	TRACK_PROGRESS = "trackProgress",
	ERROR_404 = "error404",
	SEARCH = "search",
	OPERATIONS = "operations",
	COSTS = "costs",
	DATA_DRIVEN = "dataDriven",
	CUSTOMER = "customer",
	RISK = "risk",
	SCALE = "scale",
	SOLUTIONS = "solutions",
	DEPLOYMENT = "deployment",
	IMPLEMENTATION = "implementation",
	TECH_SUPPORT = "techSupport",
	SHIELD = "shield",
	LOUPE = "loupe",
}

export enum Languages {
	DUTCH = "nl",
	ENGLISH = "en",
	GERMAN = "de",
}

export enum BG {
	HOME = "home",
	SECURITY = "security",
	BENEFITS = "benefits",
	CONTACT = "contact",
}
