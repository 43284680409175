import React from "react";
import { useTranslation } from "react-i18next";
import * as img from "../data/constants/images";

export default function Products(): JSX.Element {
	const { t } = useTranslation("Products");

	return (
		<main>
			<section className="features products">
				<h2 className="sectionHeader">{t("sectionHeader")}</h2>
				<div className="productCards">
					{/* <p className="intro">
						We develop AI that deeply integrate with your company's documents, databases, and workflows. By
						combining advanced natural language processing and retrieval-augmented generation technologies,
						we provide highly contextual, real-time insights.
					</p> */}
					<div className="logoCardContainer">
						<img className="logo" src="img/theon.png" alt={t("theon.title")} />
						<div className="card">
							{/* <h2>{t("theon.title")}</h2> */}
							<p>{t("theon.text.p1")}</p>
							<p>{t("theon.text.p2")}</p>
							<p>{t("theon.text.p3")}</p>
						</div>
					</div>
					<div className="logoCardContainer">
						<img className="logo" src="img/Evora.png" alt={t("theon.title")} />
						<div className="card">
							{/* <h2>{t("Evora.title")}</h2> */}
							<h3>{t("Evora.subtitle")}</h3>
							<p>{t("Evora.text.p1")}</p>
							<p>{t("Evora.text.p2")}</p>
						</div>
					</div>
				</div>
			</section>
		</main>
	);
}
