import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import "./styles/style.css";
import Home from "./pages/Home";
import Header from "./components/Header";
import Contact from "./pages/Contact";
import Legal from "./pages/legal/Legal";
import CookiePolicy from "./pages/legal/CookiePolicy";
import TermsOfService from "./pages/legal/TermsOfService";
import PrivacyPolicy from "./pages/legal/PrivacyPolicy";
import ErrorPage from "./pages/ErrorPage";
import Footer from "./components/Footer";
import Services from "./pages/Security";
import ErrorBoundary from "./components/ErrorBoundary";
import Benefits from "./pages/Benefits";
import { Element } from "react-scroll";
import { Parallax } from "react-parallax";
import Implementation from "./pages/Implementation";
import Clients from "./pages/Clients";
import Products from "./pages/Products";
import Security from "./pages/Security";
import FAQ from "./pages/FAQ";
import * as img from "./data/constants/images";
import * as e from "./data/enums";

export default function App() {
	return (
		<ErrorBoundary>
			<Router>
				<div className="App">
					<Header />
					<Routes>
						<Route path="/" element={<MainContent />} />
						<Route path="/contact" element={<MainContent />} />
						<Route path="/legal" element={<Legal />} />
						<Route path="/cookiepolicy" element={<CookiePolicy />} />
						<Route path="/privacypolicy" element={<PrivacyPolicy />} />
						<Route path="/termsofservice" element={<TermsOfService />} />
						{/* <Route path="/testimonials" element={<MainContent />} /> */}
						<Route path="/services" element={<MainContent />} />
						<Route path="/benefits" element={<MainContent />} />
						<Route path="/implementation" element={<MainContent />} />
						<Route path="/clients" element={<MainContent />} />
						<Route path="*" element={<ErrorPage message="Page not found" />} />
					</Routes>
					{/* <Footer /> */}
				</div>
			</Router>
		</ErrorBoundary>
	);
}

function MainContent() {
	return (
		<div>
			<Parallax bgImage={img.BG[e.BG.HOME]} strength={1000} bgImageStyle={{ opacity: 0.05 }}>
				{/* <Parallax bgImage={img.BG[e.BG.HOME]} strength={600} bgImageStyle={{ opacity: 0.05 }}> */}
				<Element name="home" className="section">
					<Home />
				</Element>
				{/* </Parallax> */}
				{/* <Parallax bgImage="/img/bg_00.jpg" strength={500}> */}
				<Element name="benefits" className="section">
					<Benefits />
				</Element>
				{/* </Parallax> */}
				{/* <Parallax bgImage="/img/bg_01.jpg" strength={500}> */}
				<Element name="services" className="section">
					<Security />
				</Element>
				{/* </Parallax> */}
				{/* <Parallax bgImage="/img/bg_00.jpg" strength={500}>
				<Element name="implementation" className="section">
					<Implementation />
				</Element>
			</Parallax> */}
				<Element name="products" className="section">
					<Products />
				</Element>
				{/* </Parallax> */}
				{/* <Element name="clients" className="section">
				<Clients />
			</Element> */}
				{/* </Parallax> */}
				{/* <Parallax bgImage="/img/bg_02.jpg" strength={500}> */}
				<Element name="faq" className="section">
					<FAQ />
				</Element>
				<Element name="contact" className="section">
					<Contact />
				</Element>
			</Parallax>
		</div>
	);
}
