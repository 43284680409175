import * as e from "../enums";

export const LOGO = "/img/logo.png";

export const ICONS: Record<any, string> = {
	[e.Icons.STATISTICS]: "/img/stats.webp",
	[e.Icons.SETTINGS]: "/img/settings.webp",
	[e.Icons.TIMER]: "/img/timer.webp",
	[e.Icons.GOLD]: "/img/gold-medal.webp",
	[e.Icons.SILVER]: "/img/silver-medal.webp",
	[e.Icons.BRONZE]: "/img/bronze-medal.webp",
	[e.Icons.KNOWLEDGE]: "/img/knowledge.webp",
	[e.Icons.DIFFICULTY]: "/img/difficulty.webp",
	[e.Icons.LEARNING_CURVE]: "/img/learningCurve.webp",
	[e.Icons.SCORES]: "/img/scores.webp",
	[e.Icons.CUP]: "/img/cup.webp",
	[e.Icons.CHECK_CORRECT]: "/img/checkCorrect.webp",
	[e.Icons.TRACK_PROGRESS]: "/img/track.webp",
	[e.Icons.ERROR_404]: "/img/error404.webp",
	[e.Icons.SEARCH]: "/img/search.png",
	[e.Icons.OPERATIONS]: "/img/operations.png",
	[e.Icons.COSTS]: "/img/reduceCosts.png",
	[e.Icons.DATA_DRIVEN]: "/img/dataDriven.png",
	[e.Icons.CUSTOMER]: "/img/customer.png",
	[e.Icons.RISK]: "/img/risk.png",
	[e.Icons.SCALE]: "/img/scale.png",
	[e.Icons.SOLUTIONS]: "/img/solutions.png",
	[e.Icons.DEPLOYMENT]: "/img/deployment.png",
	[e.Icons.IMPLEMENTATION]: "/img/implementation.png",
	[e.Icons.TECH_SUPPORT]: "/img/techSupport.png",
	[e.Icons.SHIELD]: "/img/shield.png",
	[e.Icons.LOUPE]: "/img/loupe.png",
};

export const FLAGS: Record<any, string> = {
	// [e.Languages.ENGLISH_USA]: "/img/flags/usa.webp",
	[e.Languages.DUTCH]: "/img/flags/netherlands.webp",
	[e.Languages.ENGLISH]: "/img/flags/uk.webp",
	[e.Languages.GERMAN]: "/img/flags/germany.webp",
};

export const BG: Record<any, string> = {
	[e.BG.HOME]: "/img/bg/bg_8.jpg",
	[e.BG.SECURITY]: "/img/bg/bg_01.jpg",
	[e.BG.BENEFITS]: "/img/bg/bg_02.jpg",
	[e.BG.CONTACT]: "/img/bg/bg_03.jpg",
};
