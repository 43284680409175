import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { LOGO } from "../data/constants/images";
import { useTranslation } from "react-i18next";
import { Link as ScrollLink } from "react-scroll";
import i18next from "i18next";
import * as e from "../data/enums";
import * as img from "../data/constants/images";

export default function Header(): JSX.Element {
	const [changeLanguage, setChangeLanguage] = React.useState(false);
	const chooseLanguageRef = useRef<HTMLDivElement>(null);

	const { t } = useTranslation("Header");
	const navigate = useNavigate();

	// Handling click outside to reset changeLanguage
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (chooseLanguageRef.current && !chooseLanguageRef.current.contains(event.target as Node))
				setChangeLanguage(false);
		};

		document.body.addEventListener("click", handleClickOutside);
		return () => document.body.removeEventListener("click", handleClickOutside);
	}, []);

	return (
		<header>
			<div className="headerContainer">
				<ScrollLink smooth={true} duration={500} to="home">
					<img id="logo" alt={t("header.logoAlt")} src={LOGO} />
				</ScrollLink>
				{/* <SearchBar /> */}
				<Menu />
				{/* <ChooseLanguage
				ref={chooseLanguageRef}
				changeLanguage={changeLanguage}
				setChangeLanguage={setChangeLanguage}
				/> */}
				{/* <Menu /> */}
			</div>
		</header>
	);
}

function Menu() {
	const [isOpen, setIsOpen] = React.useState(false);
	const toggleMenu = () => setIsOpen(!isOpen);

	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}
	}, [isOpen]);

	return (
		<>
			<div className="hamburger" onClick={toggleMenu}>
				<div className="line"></div>
				<div className="line"></div>
				<div className="line"></div>
			</div>
			<MenuItems isOpen={isOpen} setIsOpen={setIsOpen} />
		</>
	);
}

function MenuItems({
	isOpen,
	setIsOpen,
}: {
	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const closeMenu = () => setIsOpen(false);

	// check window size and set menuItemsSmall if window is smaller than 600px
	const [windowSize, setWindowSize] = React.useState(window.innerWidth);

	React.useEffect(() => {
		const handleResize = () => {
			setWindowSize(window.innerWidth);
		};
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	if (windowSize < 600 && !isOpen) return null;
	return (
		<div className={windowSize < 600 ? "menuItemsSmall" : "menuItems"}>
			<ScrollLink onClick={closeMenu} smooth={true} duration={500} to="home">
				Home
			</ScrollLink>
			<ScrollLink onClick={closeMenu} smooth={true} duration={500} to="benefits">
				Benefits
			</ScrollLink>
			<ScrollLink onClick={closeMenu} smooth={true} duration={500} to="security">
				Security
			</ScrollLink>
			<ScrollLink onClick={closeMenu} smooth={true} duration={500} to="products">
				Products
			</ScrollLink>
			<ScrollLink onClick={closeMenu} smooth={true} duration={500} to="faq">
				FAQ
			</ScrollLink>
			{/* <ScrollLink smooth={true} duration={500} to="clients">
				Who we help
				</ScrollLink> */}
			<ScrollLink onClick={closeMenu} smooth={true} duration={500} to="contact">
				Contact
			</ScrollLink>
		</div>
	);
}

// function SearchBar() {
// 	const { t } = useTranslation("Header");
// 	const navigate = useNavigate();
// 	const [search, setSearch] = React.useState("");
// 	const [searchFocus, setSearchFocus] = React.useState(false);

// 	return (
// 		<div className="searchBar flexRow">
// 			<input
// 				type="text"
// 				placeholder={t("header.searchBar.placeholder")}
// 				value={search}
// 				onChange={(e) => setSearch(e.target.value)}
// 				onFocus={() => setSearchFocus(true)}
// 				onBlur={() => setSearchFocus(false)}
// 			/>
// 			<img
// 				onClick={() => navigate("/Search")}
// 				alt={t("header.searchBar.searchIconAlt")}
// 				src={img.ICONS[e.Icons.SEARCH]}
// 			/>
// 		</div>
// 	);
// }

// const ChooseLanguage = React.forwardRef<
// 	HTMLDivElement,
// 	{
// 		changeLanguage: boolean;
// 		setChangeLanguage: React.Dispatch<React.SetStateAction<boolean>>;
// 	}
// >(({ changeLanguage, setChangeLanguage }, ref) => {
// 	const currentLanguage = i18next.language as e.Languages;
// 	const [currentLanguageFlag, setCurrentLanguageFlag] = React.useState(img.FLAGS[currentLanguage]);

// 	const handleSelectLanguage = (lang: string) => {
// 		setChangeLanguage(false);
// 		if (lang === currentLanguage) return;
// 		i18next.changeLanguage(lang);
// 		localStorage.setItem("language", lang);
// 		setCurrentLanguageFlag(img.FLAGS[lang]);
// 	};

// 	return (
// 		<div className="language" ref={ref}>
// 			{changeLanguage && (
// 				<div className="flagPopup">
// 					<img
// 						className={currentLanguage === e.Languages.DUTCH ? "flag flagActive" : "flag"}
// 						src={img.FLAGS[e.Languages.DUTCH]}
// 						onClick={() => handleSelectLanguage(e.Languages.DUTCH)}
// 						alt="Dutch"
// 						title="Dutch"
// 					/>
// 					<img
// 						className={currentLanguage === e.Languages.ENGLISH ? "flag flagActive" : "flag"}
// 						src={img.FLAGS[e.Languages.ENGLISH]}
// 						onClick={() => handleSelectLanguage(e.Languages.ENGLISH)}
// 						alt="English"
// 						title="English"
// 					/>

// 					<img
// 						className={currentLanguage === e.Languages.GERMAN ? "flag flagActive" : "flag"}
// 						src={img.FLAGS[e.Languages.GERMAN]}
// 						onClick={() => handleSelectLanguage(e.Languages.GERMAN)}
// 						alt="German"
// 						title="German"
// 					/>
// 				</div>
// 			)}
// 			<img
// 				className="flag"
// 				src={currentLanguageFlag}
// 				onClick={() => setChangeLanguage(true)}
// 				alt="Change Language"
// 			/>
// 		</div>
// 	);
// });
