import React from "react";
import { useTranslation } from "react-i18next";
import * as img from "../data/constants/images";
import * as e from "../data/enums";
import InfoCard from "../components/InfoCard";

export default function Security(): JSX.Element {
	const { t } = useTranslation("Security");

	const infoCards = [
		{
			imgSrc: img.ICONS[e.Icons.SOLUTIONS],
			title: t("1.title"),
			text: t("1.text"),
		},
		{
			imgSrc: img.ICONS[e.Icons.SOLUTIONS],
			title: t("2.title"),
			text: t("2.text"),
		},
		{
			imgSrc: img.ICONS[e.Icons.SOLUTIONS],
			title: t("3.title"),
			text: t("3.text"),
		},
		{
			imgSrc: img.ICONS[e.Icons.SOLUTIONS],
			title: t("4.title"),
			text: t("4.text"),
		},
		{
			imgSrc: img.ICONS[e.Icons.SOLUTIONS],
			title: t("5.title"),
			text: t("5.text"),
		},
	];

	return (
		<main>
			<section className="features security">
				{/* <img className="bgImg" src={img.BG[e.BG.SECURITY]} /> */}
				<div className="containterHeading">
					<div className="containterText">
						<h2 className="sectionHeader">{t("header")}</h2>
						<h3 className="intro">{t("intro")}</h3>
					</div>
					<img src={img.ICONS[e.Icons.SHIELD]} />
				</div>
				<div className="benefitCards">
					<InfoCard title={t("1.title")} text={t("1.text")} />
					<InfoCard title={t("2.title")} text={t("2.text")} />
					<InfoCard title={t("3.title")} text={t("3.text")} />
					<InfoCard title={t("4.title")} text={t("4.text")} />
					{/* <InfoCard imgSrc={image.ICONS[e.Icons.SCALE]} title={t("5.title")} text={t("5.text")} /> */}
				</div>
				{/* <h3>{t("outro")}</h3> */}
			</section>
		</main>
	);
}
