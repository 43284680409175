import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import * as img from "../data/constants/images";
import * as e from "../data/enums";
import InfoCard from "../components/InfoCard";
import { Element } from "react-scroll";

export default function Benefits(): JSX.Element {
	const { t } = useTranslation("Benefits");
	const featureSectionRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleScroll = () => {
			const featureSection = featureSectionRef.current;
			if (featureSection) {
				const rect = featureSection.getBoundingClientRect();
				if (rect.top <= 0 && rect.bottom >= window.innerHeight) {
					const scrollLeft = window.scrollY - rect.top;
					featureSection.scrollLeft = scrollLeft;
				}
			}
		};

		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<Element name="benefits">
			<main>
				<section className="features benefits">
					<h2 className="sectionHeader">{t("header1")}</h2>
					<div className="wrapper">
						<div className="containterHeading">
							{/* {window.innerWidth > 750 && <img className="bgImg" src={img.BG[e.BG.BENEFITS]} />} */}
							<div className="containterText">
								<h3 className="intro">{t("intro")}</h3>
							</div>
							<div className="iconBG">
								<img className="icon" src={img.ICONS[e.Icons.LOUPE]} />
							</div>
						</div>
						<div className="benefitCards" ref={featureSectionRef}>
							{/* <InfoCard title={t("1.title")} text={t("1.text")} />
							<InfoCard title={t("2.title")} text={t("2.text")} />
							<InfoCard title={t("4.title")} text={t("4.text")} />
							<InfoCard title={t("5.title")} text={t("5.text")} /> */}
							<InfoCard imgSrc={img.ICONS[e.Icons.SEARCH]} title={t("1.title")} text={t("1.text")} />
							<InfoCard imgSrc={img.ICONS[e.Icons.CUSTOMER]} title={t("2.title")} text={t("2.text")} />
							<InfoCard imgSrc={img.ICONS[e.Icons.SOLUTIONS]} title={t("4.title")} text={t("4.text")} />
							{/* <InfoCard imgSrc={img.ICONS[e.Icons.DATA_DRIVEN]} title={t("3.title")} text={t("3.text")} /> */}
							<InfoCard imgSrc={img.ICONS[e.Icons.SCALE]} title={t("5.title")} text={t("5.text")} />
						</div>
					</div>
				</section>
			</main>
		</Element>
	);
}
